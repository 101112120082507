import { ConfigProvider, App } from 'antd';
import React, { useEffect } from 'react';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import zhCN from 'antd/es/locale/zh_CN';
// import moment from "moment";
import "./App.css"
// import TimezoneProiver from './components/TimezoneProvier';
// import "moment/locale/zh-cn";
import AuthLoading from './components/AuthLoading';

// console.log("env=>", moment.tz.names());

// moment.locale("zh-cn");

export default (props: any) => {

  const routes: any[] = props.routes;


  const renderRoute = (route: any, index: number) => {

    const hasChildren = route.children ? true : false;
    const children = hasChildren ? [...route.children] : [];
    return (
      <>
        <Route key={route.path} path={route.path} index={route.index} element={

          hasChildren ? <route.layout>
            <Routes>
              {children.map((it, i) => {
                return renderRoute(it, i);
              })}
            </Routes>
          </route.layout> : <route.component />
        } >
        </Route>
      </>
    )


  };


  console.log("routes=>",routes);


  return (


    <ConfigProvider locale={zhCN} theme={{
      token: {
        colorPrimary: "#00b96b",
        // primary
        // borderRadius: 1.5
      }
    }} >

      {routes ? <>
        <App style={{ width: "100%", height: "100%" }}>
          <BrowserRouter>
            <Routes>
              {routes.map((it, i) => {
                return renderRoute(it, i);
              })}
            </Routes>
          </BrowserRouter>
        </App>
      </> : <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>

        <AuthLoading />
      </div>}


    </ConfigProvider>

  )

};
