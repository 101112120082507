import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import routes from './config/routes';
import AuthLoading from './components/AuthLoading';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );



// console.log("kaic");

const route = routes();

const RouteApp = () => {

  const [routes, setRoutes] = useState(null);

  useEffect(() => { 

    // console.log("start=>");
    route?.then(routeList => {

      setRoutes(routeList);

    }).catch(() => {});


  },[]);



  return (
    <App routes={routes} />
   
  )

}








// root.render(
  
// );


ReactDOM.render(
<React.StrictMode>
    <RouteApp />
  </React.StrictMode>,
  document.getElementById("root")
)




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
