const mod = process.env["REACT_APP_MOD"];


type MOD = "admin"|"h5"|any


function importRoutes (mod:MOD) : Promise<any>| null {
 
    if(mod === "admin"){
        return import("../modules/admin/config/routes").then(ret=>ret.default);
    }

    if(mod === "h5"){
        return import("../modules/h5/config/routes").then(ret=>ret.default);
    }

    return null
}




export default ():Promise<any>|null=>{


    return importRoutes(mod);

};