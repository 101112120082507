import React, { Component, FC, PropsWithChildren } from "react";
import { Spin, Layout } from "antd";
import { LoadingOutlined } from '@ant-design/icons';


const AuthLoading: FC<PropsWithChildren> = ({ children }) => {




    return (
        <Layout style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100%", flex: 1, backgroundColor: "#fff" }}>

            <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} />}></Spin>
            {children}
        </Layout>
    )


}

export default AuthLoading;

